$colorTextPrimary: #191A23;

$colorBlack: #08010F;
$colorGrey: #9A96A0;

$colorDanger: #CD4246;
$colorWarning: #faad14;
$colorSuccess: #238552;
$colorLpBlue: #1802D0;
$colorLpYellow: #F1C946;

$colorPrimary: $colorLpBlue;
$colorBrandMain: $colorLpBlue;

$colorWhite: rgb(255, 255, 255);
$colorWhiteHover: rgba(255, 255, 255, 0.75);
$colorWhiteActive: rgba(255, 255, 255, 0.5);
$colorBgDashboard: #CFD8E3;

$colorBorder: #d9d9d9;
$colorBorderHover: #3e26de;


$navbar-height: 60px;